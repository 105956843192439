import api from '@api'
// import SortBy from 'lodash/sortBy'
import Constants from '@constants'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
  transformAvailableQualification,
  buildModuleQuery,
  buildUnaryQualificationStructure,
  buildRelationalQualificationStructure,
  transformBinaryQualForServer,
} from '@data/qualification'
import {
  getRootTranslator,
  getModulePluaralTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformKpi,
  transformKpiForServer,
  transformWidget,
  transformWidgetForServer,
  transformShortcut,
  transformShortcutForServer,
  transformDashboardForServer,
  transformDashboard,
  transformAllDashletsForServer,
  transformWidgetTable,
  transformManagedDashboard,
  transformManagedDashboardList,
  transformManagedDashboardForServer,
  transformCreateDashboard,
  transformCreateDashboardForServer,
} from '@data/dashboard'
import {
  transformReportSchedule,
  transformReportScheduleForServer,
  transformAvailableColumn,
} from '@data/report'
import { transformMyApproval } from '@data/approval'
import { transformTask } from '@data/task'
import { transformTicketForList } from '@data/ticket'
import { transformUserSessions } from '@data/user'
import { transformAdminAudit } from '@data/admin-audit'
import ModuleConfig from './config'
import { generateId } from '@utils/id'
import { errorToast } from '@motadata/ui'

const __dashboardTc = getModulePluaralTranslator(ModuleConfig.translationKey)
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  quals.push(
    buildRelationalQualificationStructure(
      'type',
      'equal',
      'dashboard',
      'enum',
      'db'
    )
  )
  return buildFlatQualificationStructure(quals)
}
export function getSupportDataApi(moduleName, additionalUrlParams = {}) {
  return api
    .get(`/analytics/dashboard/${moduleName}/supportedData`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      const transformedData = {}
      transformedData.dateFilters = data.dateFilter.map(
        transformAvailableColumn
      )
      transformedData.widget = data.widget.map(transformAvailableColumn)
      return transformedData
    })
}

export function getAvailableQualificationsApi(
  moduleName,
  additionalUrlParams = {}
) {
  return api
    .get(`analytics/dashboard/${moduleName}/supportedQual`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      return (data || []).map(transformAvailableQualification)
    })
}
export function getManagedDashboardListBkApi() {
  return api.get(`analytics/dashboard/mylist`).then((data) => {
    return (data.objectList || []).map(transformManagedDashboardList)
  })
}

export function getManagedDashboardListApi(limit, offset) {
  return api
    .post(`analytics/dashboard/search/byqual`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
        // ...{ sort_by: '-objOrder' },
      },
    })
    .then((data) => {
      return (data.objectList || []).map(transformManagedDashboardList)
    })
}

export function getDashboardApi(id) {
  return api.get(`analytics/dashboard/${id}`).then(transformDashboard)
}

export function dashboardRemoveSearchApi(id) {
  return api.delete(`analytics/dashboard/${id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('dashboard'),
    }),
  })
}

export function getManagedDashboardApi(filter, limit, offset) {
  return api
    .post(
      `analytics/dashboard/search/byqual`,
      {
        qualDetails:
          filter.name || filter.enabled
            ? buildQualifications(filter)
            : buildFlatQualificationStructure([
                buildRelationalQualificationStructure(
                  'type',
                  'equal',
                  'dashboard',
                  'enum',
                  'db'
                ),
              ]),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          // ...{ sort_by: '-objOrder' },
        },
      }
    )
    .then((data) => {
      // const list = SortBy(data.objectList || [], (d) => d.objOrder)
      // return {
      //   items: list.map(transformManagedDashboard),
      //   total: data.totalCount,
      // }
      return {
        items: (data.objectList || []).map(transformManagedDashboard),
        total: data.totalCount,
      }
    })
}

export function updateManagedDashboardDashletsApi(data) {
  return api
    .patch(
      `analytics/dashboard/template/${data.id}`,
      transformAllDashletsForServer(data.dashlets),
      {
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then(transformDashboard)
}
export function updateManagedDashboardApi(data) {
  return api
    .patch(
      `analytics/dashboard/template/${data.id}`,
      transformManagedDashboardForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('dashboard'),
        }),
      }
    )
    .then(transformManagedDashboard)
}

export function toggleEnableApi(id, data) {
  return api
    .patch(`analytics/dashboard/template/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('dashboard'),
      }),
    })
    .then((data) => transformManagedDashboard(data))
}

export function copyManagedDashboardApi(item) {
  return api
    .get(
      `analytics/dashboard/template/clone/${item.id}`,
      {},
      {
        message: __rootT('created_successfully', {
          resource: __rootTc('dashboard'),
        }),
      }
    )
    .then(transformManagedDashboard)
}

export function bulkUpdateManagedDashboardApi(moduleName, items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`analytics/${moduleName}/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: __rootTc('dashboard'),
    }),
  })
}
export function updateDashboardApi(data, notify) {
  return api
    .patch(
      `/analytics/dashboard/${data.id}`,
      transformDashboardForServer(data),
      {
        ...(notify
          ? {
              message: __rootT('created_successfully', {
                resource: __rootTc('dashboard'),
              }),
            }
          : {}),
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then(transformDashboard)
}

export function updateDashboardDashlets(
  id,
  dashlets,
  currentSelectedDashboardId
) {
  return api
    .patch(
      `/analytics/dashboard/${currentSelectedDashboardId}`,
      transformAllDashletsForServer(dashlets),
      {
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then(transformDashboard)
}

function buildPrivacyFilter(filter) {
  switch (filter) {
    case 'predefined':
      return [buildUnaryQualificationStructure('oobType', 'is_not_null', 'db')]
    case 'created_by_me':
      return [
        buildUnaryQualificationStructure('oobType', 'is_null', 'db'),
        buildRelationalQualificationStructure(
          'createdById',
          'equal',
          'current_user',
          'variable',
          'db'
        ),
      ]
    case 'shared_with_me':
      return [
        transformBinaryQualForServer(
          buildRelationalQualificationStructure(
            'userIds',
            'any_member_or_all_members_exist',
            'current_user',
            'variable',
            'db'
          ),
          buildRelationalQualificationStructure(
            'groupIds',
            'any_member_or_all_members_exist',
            'my_group',
            'variable',
            'db'
          ),
          'or'
        ),
      ]
    default:
      return []
  }
}

export function getKpisApi(filterFactor, limit, offset) {
  const moduleName = filterFactor.moduleName
    ? filterFactor.moduleName
    : Constants.CONTACTOPS
  return api
    .post(
      `analytics/kpi/search/byqual`,
      {
        qualDetails:
          filterFactor.name || moduleName || filterFactor.privacyFilter
            ? buildFlatQualificationStructure(
                [
                  filterFactor.name && buildNameFilterQuery(filterFactor.name),
                  moduleName &&
                    buildModuleQuery(
                      moduleName,
                      moduleName === Constants.CONTACTOPS
                        ? 'not_equal'
                        : undefined
                    ),
                ]
                  .filter(Boolean)
                  .concat(
                    filterFactor.privacyFilter
                      ? buildPrivacyFilter(filterFactor.privacyFilter)
                      : []
                  )
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformKpi),
        total: data.totalCount,
      }
    })
}

export function createKpiApi(data) {
  return api
    .post(`analytics/kpi`, transformKpiForServer(data), {
      message: __rootT('created_successfully', {
        resource: __dashboardTc('kpi'),
      }),
    })
    .then(transformKpi)
}

export function getKpiApi(id) {
  return api.get(`analytics/kpi/${id}`).then(transformKpi)
}

export function updateKpiApi(data) {
  return api
    .patch(`analytics/kpi/${data.id}`, transformKpiForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __dashboardTc('kpi'),
      }),
    })
    .then(transformKpi)
}

export function deleteKpiApi(data) {
  return api.delete(`analytics/kpi/${data.id}`, {
    message: __rootT('deleted_successfully', {
      resource: __dashboardTc('kpi'),
    }),
  })
}

export function getWidgetsApi(filterFactor, limit, offset) {
  return api
    .post(
      `analytics/widget/search/byqual`,
      {
        qualDetails:
          filterFactor.name ||
          filterFactor.moduleName ||
          filterFactor.privacyFilter
            ? buildFlatQualificationStructure(
                [
                  filterFactor.name && buildNameFilterQuery(filterFactor.name),
                  filterFactor.moduleName &&
                    buildModuleQuery(filterFactor.moduleName),
                ]
                  .filter(Boolean)
                  .concat(
                    filterFactor.privacyFilter
                      ? buildPrivacyFilter(filterFactor.privacyFilter)
                      : []
                  )
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWidget),
        total: data.totalCount,
      }
    })
}

export function createWidgetApi(data) {
  return api
    .post(`analytics/widget`, transformWidgetForServer(data), {
      message: __rootT('created_successfully', {
        resource: __dashboardTc('widget'),
      }),
    })
    .then(transformWidget)
}

export function getWidgetApi(id) {
  return api.get(`analytics/widget/${id}`).then(transformWidget)
}

export function updateWidgetApi(data) {
  return api
    .patch(`analytics/widget/${data.id}`, transformWidgetForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __dashboardTc('widget'),
      }),
    })
    .then(transformWidget)
}

export function deleteWidgetApi(data) {
  return api.delete(`analytics/widget/${data.id}`, {
    message: __rootT('deleted_successfully', {
      resource: __dashboardTc('widget'),
    }),
  })
}

export function getShortcutsApi(filterFactor, limit, offset) {
  return api
    .post(
      `analytics/shortcut/search/byqual`,
      {
        qualDetails:
          filterFactor.name ||
          filterFactor.moduleName ||
          filterFactor.privacyFilter
            ? buildFlatQualificationStructure(
                [
                  filterFactor.name && buildNameFilterQuery(filterFactor.name),
                  filterFactor.moduleName &&
                    buildModuleQuery(filterFactor.moduleName),
                ]
                  .filter(Boolean)
                  .concat(
                    filterFactor.privacyFilter
                      ? buildPrivacyFilter(filterFactor.privacyFilter)
                      : []
                  )
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformShortcut),
        total: data.totalCount,
      }
    })
}

export function createShortcutApi(data) {
  return api
    .post(`analytics/shortcut`, transformShortcutForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('shortcut'),
      }),
    })
    .then(transformShortcut)
}

export function getShortcutApi(id) {
  return api.get(`analytics/shortcut/${id}`).then(transformShortcut)
}

export function updateShortcutApi(data) {
  return api
    .patch(`analytics/shortcut/${data.id}`, transformShortcutForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('shortcut'),
      }),
    })
    .then(transformShortcut)
}

export function deleteShortcutApi(data) {
  return api.delete(`analytics/shortcut/${data.id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('shortcut'),
    }),
  })
}

export function getKpiPreviewApi(id, filters) {
  return api
    .post(
      `analytics/kpi/preview/${id}`,
      {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      {
        notify: false,
      }
    )
    .then((data) => {
      return {
        value: data.value,
        kpi: transformKpi({ ...data.kpi, qual: data.qual }),
      }
    })
}

export function getWidgetPreviewApi(id, filters) {
  return api
    .post(
      `analytics/widget/preview/${id}`,
      {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      {
        timeout: 0,
        notify: false,
      }
    )
    .then((data) => {
      return {
        widget: transformWidget({ ...data.widget, qual: data.table.qual }),
        data: transformWidgetTable(data.table),
      }
    })
}

export function getShortcutPreviewApi(id, type, filters, limit, offset) {
  return api
    .get('analytics/dashboard/shortcut/preview', {
      params: {
        offset: offset || 0,
        id,
        size: limit === undefined ? 1000 : limit,
        // startDate: filters.startDate,
        // endDate: filters.endDate,
        ...((filters || {}).startDate ? { startDate: filters.startDate } : {}),
        ...((filters || {}).endDate ? { endDate: filters.endDate } : {}),
      },
      notify: false,
    })
    .then((data) => {
      const d = (data.previewData || []).map((t) => {
        if (type === 'approval') {
          return transformMyApproval(t)
        }
        if (type === 'task') {
          return transformTask(t)
        }
        if (type === 'request') {
          return transformTicketForList(t)
        }
        if (type === 'online_technician') {
          return transformUserSessions({ ...t, id: generateId() })
        }
        return t
      })
      return {
        items: d,
        // to disable pagination
        total: (d || []).length === 0 ? 0 : d.length > limit ? d.length : limit,
        shortcutDetails: transformShortcut(data.shortcutRest),
      }
    })
}

export function getDashboardListApi(filterFactor, limit, offset) {
  return api
    .post(
      `analytics/dashboard/search/byqual`,
      {
        qualDetails: filterFactor.oobType
          ? buildFlatQualificationStructure(
              [
                filterFactor.oobType &&
                  buildUnaryQualificationStructure(
                    'oobType',
                    filterFactor.oobType,
                    'db'
                  ),
                buildRelationalQualificationStructure(
                  'type',
                  'equal',
                  'dashboard',
                  'enum',
                  'db'
                ),
              ].filter(Boolean)
            )
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList || [],
        total: data.totalCount,
      }
    })
}

export function exportDashboardApi(id, data) {
  return api.post(
    `analytics/dashboard/${id}/export`,
    {
      emailAddresses: data.emails,
      startDate: data.startDate,
      endDate: data.endDate,
      type: data.type,
      enabled: data.enabled,
      attachmentPassword: data.protectedPassword,
    },
    {
      params: {
        exporttype: data.exportFormat,
      },
      notification: {
        message: __rootT('success'),
        description: __rootT('resource_will_be_sent', {
          resource: __rootTc('dashboard'),
        }),
      },
    }
  )
}
export function createDashboardApi(dashboard) {
  return api
    .post(`analytics/dashboard`, transformCreateDashboardForServer(dashboard), {
      message: __rootT('created_successfully', {
        resource: __rootTc('dashboard'),
      }),
    })
    .then((data) => transformCreateDashboard(data))
}

export function cloneDashboardApi(id) {
  return api.get(`analytics/dashboard/clone/${id}`)
}

export function getDashboardHistoryApi(filter, pageSize, offset) {
  return api
    .post(`/dashboardactivity`, filter, {
      params: {
        size: pageSize || 1000,
        offset: offset || 0,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformAdminAudit),
        total: data.totalCount,
      }
    })
}

export function downloadWidgetApi(filters, id, type) {
  return api
    .post(
      `analytics/widget/download/${id}`,
      {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      {
        timeout: 0,
        responseType: 'blob',
        params: {
          exporttype: type,
        },
        notify: false,
      }
    )
    .catch((error) => {
      if ((error.response || {}).status === 500) {
        const reader = new Response(error.response.data).text()
        reader.then((d) => {
          const message = JSON.parse(d).userMessage
          errorToast(message)
        })
      }
    })
}

// ************** start dashboard schedule curd apis ******************** //
export function createDashboardScheduleApi(dashboardId, data) {
  return api
    .post(
      `analytics/dashboard/${dashboardId}/scheduler`,
      transformReportScheduleForServer(data),
      {
        message: __rootT('created_successfully', {
          resource: __rootTc('schedule'),
        }),
      }
    )
    .then(transformReportSchedule)
}

export function updateDashboardScheduleApi(dashboardId, data) {
  return api
    .patch(
      `analytics/dashboard/${dashboardId}/scheduler/${data.id}`,
      transformReportScheduleForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('schedule'),
        }),
      }
    )
    .then(transformReportSchedule)
}

export function deleteDashboardScheduleApi(dashboardId, id) {
  return api.delete(`analytics/dashboard/${dashboardId}/scheduler/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('schedule'),
    }),
  })
}

export function getDashboardSchedulesApi(
  filterFactor,
  dashboardId,
  limit,
  offset
) {
  return api
    .post(
      `analytics/dashboard/${dashboardId}/scheduler/search/byqual`,
      {
        qualDetails: filterFactor.name
          ? buildFlatQualificationStructure(
              [
                filterFactor.name && buildNameFilterQuery(filterFactor.name),
              ].filter(Boolean)
            )
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformReportSchedule),
        total: data.totalCount,
      }
    })
}
// **************** end dashboard schedule curd apis ******************** //
